export default class ColorService 
{
    getColors() {
        return fetch('data/colors.json').then(res => res.json()).then(d => d.data);
    }

    convertToSN(fullName) {
        // Split the full name into individual words
        const words = fullName.split(' ');
      
        // Initialize an empty string to store the initials
        let initials = '';
      
        // Iterate through the words and append the first character of each word to the initials string
        for (const word of words) {
          initials += word.substring(0, 1);;
        }
      
        // Return the initials in uppercase
        return initials.toUpperCase();
      }
}