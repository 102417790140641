<template>
	<div class="layout-footer">
		<img alt="Logo" :src="footerImage()" height="50" class="mr-2" />
		by
		<span class="font-medium ml-2">eTicket</span>
	</div>
</template>

<script>
	export default {
		name: "AppFooter",
		methods: {
			footerImage() {
				return this.$appState.darkTheme ? 'images/eTicket_logo_new.png' : 'images/eTicket_logo_new.png';
			}
		},
		computed: {
			darkTheme() {
				return this.$appState.darkTheme;
			}
		}
	}
</script>
<style scoped>
.layout-topbar,.layout-sidebar{
 
  	box-shadow: 0 2px 6px #274461!important;
  	border-color: #274461 !important;
	background-color:#274461 !important;
                    
}
.layout-topbar .layout-topbar-button{
  color: white;
}
.layout-menu li a{
  color: white;
}
.layout-menu li a:hover{
  color:black;
}

body{
	background-color: #EFF3F8;
}
/* .layout-main-container{
    background-color: black !important;
}
.layout-wrapper{
    background-color: black !important;
} */
</style>
