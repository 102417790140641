<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="set_menu" @menuitem-click="onMenuItemClick" />
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
        <VueSimpleAlert />
      </div>
      <AppFooter />
    </div>

    <AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" />
    <transition name="layout-mask">
      <div
        class="layout-mask p-component-overlay"
        v-if="mobileMenuActive"
      ></div>
    </transition>
  </div>
</template>

<script>
import apis from "@/apis";
import AppTopBar from "./AppTopbar.vue";
import AppMenu from "./AppMenu.vue";
import AppConfig from "./AppConfig.vue";
import AppFooter from "./AppFooter.vue";
import Menu from "./service/Menu";

export default {
  emits: ["change-theme"],
  data() {
    return {
      Menu_master: null,
      autoValue: null,
      
      layoutMode: "static",
      staticMenuInactive: true,
      overlayMenuActive: true,
      mobileMenuActive: false,
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
     buildMenu(menuItems) 
      {
        const result = [];
        for (const menuItem of menuItems) 
        {
          
          var menu = {
                items:[{label: menuItem.menu_name,
             icon: menuItem.menu_icon,
             to: menuItem.menu_url}]
                };


          if (menuItem.submenu && menuItem.submenu.length > 0) 
            { 
              var menu = {
                label: menuItem.menu_name,
                icon: menuItem.menu_icon,
                items: [],
              };
              menu.items = this.buildMenu(menuItem.submenu);
            }
            else
            {
              var menu = {
                items:[{label: menuItem.menu_name,
             icon: menuItem.menu_icon,
             to: menuItem.menu_url}]
             
              
              };
            }
    
            result.push(menu);
        }
          return result;
      },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
      }

      return true;
    },
  },
  created() {
    
  },
  mounted() {
    
    // var user_name = localStorage.getItem("user_type");
    // if (user_name == "admin") {
    //   this.set_menu = this.menuAdmin;
      
    // } else if (user_name == "teacher") {
    //   this.set_menu = this.menuTeacher;
      
    // } else if (user_name == "assteacher") {
    //   this.set_menu = this.menuassistantTeacher;
      
    // } else if (user_name == "network") {
    //   this.set_menu = this.menunetwork;
     
    // }

    //----------------------------------------------------------------------------------------------------------------------------
    var page_name = this.$route.name;
    var user_name = localStorage.getItem("user_type");
    //alert(user_name)
    this.Menu_master = null;
    this.autoValue =null;
    this.Menu_master = new Menu();


        var data = {
          user_type: user_name
            

          };
        this.isLoadingModel = true;
          var promise = apis.getMenuList(data);
          promise.then((responseapi) => {
            this.isLoadingModel = false;
            if (responseapi.data.status == true) {
              

              // const menu = this.buildMenu(responseapi.data.data);
              //this.set_menu=this.menuAdmin;
              // this.set_menu =menu;

              this.set_menu=[{items:responseapi.data.data}];
            
            // to create new ui
            //   this.set_menu=[{items: [{label: "Menu Master",
            //   icon: "pi pi-fw pi-angle-right",
            //   to: "/menu_master",
            // }]}];


             
              
            } else {
              this.$swal("error!!!");
            }
          });

    // if (user_name == "admin") 
    // { //alert(1);
      //   this.Menu_master.get_menuAdmin().then((Admin_data) => {
      //   this.autoValue = Admin_data;
      //   if (this.autoValue.indexOf(page_name.toString()) !== -1) {console.log("Yes, the value exists!");} else {this.$router.push("/access");}
      // });
    //   this.set_menu = this.menuAdmin;
    // } 
    // else if (user_name == "teacher") 
    // { //alert(2);
      //   this.Menu_master.get_menuTeacher().then((Teacher_data) => {
      //   this.autoValue = Teacher_data;
      //   if (this.autoValue.indexOf(page_name.toString()) !== -1) {console.log("Yes, the value exists!");} else {this.$router.push("/access");}
      // });
    //   this.set_menu = this.menuTeacher;
    // } 
    // else if (user_name == "assteacher") 
    // { //alert(3);
      // this.Menu_master.get_menuassistantTeacher().then((Ass_Teacher_data) => {
      //   this.autoValue = Ass_Teacher_data;
      //   if (this.autoValue.indexOf(page_name.toString()) !== -1) {console.log("Yes, the value exists!");} else {this.$router.push("/access");}
      // });
    //   this.set_menu = this.menuassistantTeacher;
    // } 
    // else if (user_name == "network")
    // { //alert(4);
      // this.Menu_master.get_menunetwork().then((Network_data) => {
      //   this.autoValue = Network_data;
      //   if (this.autoValue.indexOf(page_name.toString()) !== -1) {console.log("Yes, the value exists!");} else {this.$router.push("/access");}
      // });
    //   this.set_menu = this.menunetwork;
    // }
    // else if(user_name == "student")
    // {
    //   this.set_menu = this.menustudent;
    // }


  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },
    logo() {
      return this.$appState.darkTheme
        ? "images/logo-white.svg"
        : "images/logo.svg";
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    AppConfig: AppConfig,
    AppFooter: AppFooter,
  },
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
